import React from "react";
import SEO from "../../components/seo";
import useProfilePic from "../../hooks/useProfilePic";
import ProfilePage from "../../layouts/ProfilePage";

const Marcelo = () => {
	const { marcelo: pic } = useProfilePic();
	return (
		<>
			<SEO
				title="Our Team of Specialists"
				keywords={[`Paediatric Gastroenterologist`, `Dr Marcelo Leal`]}
				description="About Dr Marcelo Leal"
			/>
			<ProfilePage
				title="Marcelo Leal - Endoscopist & Paediatric Gastroenterologist"
				name="Dr Marcelo Leal"
				img={pic.childImageSharp.fixed}
				quote="Dr Marcelo Leal is affectionately known as Dr Marshmallow to his patients"
				specialty="Inflammatory bowel disease, Cystic Fibrosis Gastroenterology"
				education={[
					"Undergraduate: University of Western Australia",
					"Postgraduate GI Training: The Royal Children's Hospital, Melbourne",
					"Fellowship: The Royal Children’s Hospital, Melbourne",
				]}
				description={[
					"Dr Marcelo Leal is a Consultant Gastroenterologist and Hepatologist who holds a substantial public appointment at Perth Children’s Hospital and also consults from his rooms at Advanced GI WA.",
					"At PCH, Dr Leal is the Inflammatory Bowel Diseases clinical lead and Cystic Fibrosis gastroenterology clinical lead. He also serves on the Scientific Management Committee for AREST CF, an early surveillance program for children with cystic fibrosis, and is an Honorary Research Associate at Telethon Kids Institute.",
				]}
				honours={[
					" Masters (MPhil): Uni of Melbourne",
					" Commendation: Harvard Medical School- Global Clinical Scholars Research Training Program",
				]}
				background={[
					"Dr Leal completed his medical degree at the University of Western Australia in 2004, and he is now a Fellow of the Royal Australasian College of Physicians (FRACP) as a specialist Paediatric Gastroenterologist and Hepatologist.",
					"He recently returned home to Perth having completed his sub-speciality training at the Royal Children’s Hospital in Melbourne, where he also held a permanent Consultant position since 2016.",
					"Dr Marcelo Leal is accredited to perform paediatric gastroscopy and colonoscopy by the Conjoint Committee for Recognition of Training in Gastrointestinal Endoscopy.",
					"He will soon complete a Masters by Research at the University of Melbourne, and he completed the Global Clinical Scholars Research Training Program at Harvard Medical School, Harvard University, in 2015, with Commendation.",
					"He is also fluent in Portuguese.",
				]}
				appointments={[
					"Private appointment - Hollywood Private Hospital, Perth",
					"Public appointment - Perth Children's Hospital",
				]}
				memberships={[
					"Gastroenterological Society of Australia (GESA)",
					"Australian Health Practitioner Regulation Agency (AHPRA)",
					"The Royal Australasian College of Physicians (RACP)",
				]}
				personal={[
					"Dr Leal was born in Brazil, where Marcelo is a common name. After moving to Perth when he was 6, he was called Marshmallow for a long time at school. After a brief hiatus, Dr Leal is happy to once again be called Dr Marshmallow... this time by his patients. He has a wife and four wonderful children, and he enjoys musical theatre, watching soccer, AFL and playing Floorball.",
				]}
				publications={[
					{
						publicationTitle: "Rare Case of Gastric Xanthoma in a 15-Year-Old Patient",
						authors: "Foo T. S. Y., Turner E., Leal M., & Jevon G.",
					},
					{
						publicationTitle: "Treatment of fecal impaction in children using combined polyethylene glycol and sodium picosulphate.",
						authors: "Lamanna A., Dughetti L. D., Jordan-Ely J. A., Dobson K. M., Dynan M., Foo A., Leal M., et al.",
					},
					{
						publicationTitle: "Immunoregulatory Role of Myeloid-derived Cells in Inflammatory Bowel Disease.",
						authors: "Leal M. C., Dabritz J.",
					},
					{
						publicationTitle: "Education and imaging. Gastrointestinal: botulinum toxin effective in refractory gastroparesis in paediatric practice.",
						authors: "Leal M. C., Oliver M. R., Francis P., Catto-Smith A. G.",
					},
					{
						publicationTitle: "Unusual manifestations of a common gastrointestinal disorder.",
						authors: "Rajah K., Oliver M. R., McLeod L., Orchard D., Leal M.",
					},
				]}
			/>
		</>
	);
};

export default Marcelo;
